import styled from "styled-components";

function Button(props) {
  return (
    <Wrapper>
      <div className="button-text">{props.text}</div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
    padding: 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  background-color: #F2AE30;


  .button-text {
    color: white;
  }
`;

export default Button;
