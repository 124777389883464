import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IoMenu } from "react-icons/io5";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import UmrahIcon from "../umrah.png";
import HajjIcon from "../hajj.png";
import holidayIcon from "../holiday.png";
import testimonialIcon from "../TESTIMONIALS.png";
import contactIcon from "../Contact Us.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FaFacebookSquare } from "react-icons/fa";
import homeIcon from "../Hotel.png";
import Button from "./ui/Button";
import XIcon from '@mui/icons-material/X';
import PinterestIcon from '@mui/icons-material/Pinterest';
import bgg from "../twice too travel.png";
import CallIcon from '@mui/icons-material/Call';
const Header = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/Umrah");
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleTestClick = () => {
    navigate("/Testimonials");
  };
  const handleHajjClick = () => {
    navigate("/Hajj");
  };
  const handleHolidayClick = () => {
    navigate("/Holiday");
  };
  const handleContactClick = () => {
    navigate("/ContactUs");
  };

  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  useEffect(() => {
    if (isScrollDisabled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isScrollDisabled]);

  const toggleScroll = () => {
    setIsScrollDisabled(!isScrollDisabled);
  };

  window.addEventListener("scroll", function () {
    const scrollY = window.scrollY;
    if (scrollY <= 0) {
      setisScrolling(false);
    } else {
      setisScrolling(true);
    }
  });

  const phoneNumber = "+447832314946";

  const [isScrolling, setisScrolling] = useState(false);

  return (
    <Wrapper>
      <div className="screen">
        <div className="topline">
          <div className="IconHolder">
            <a href="https://www.facebook.com/share/oyyhaEMF43htcbXZ/?mibextid=LQQJ4d">
              <FaFacebookSquare className="social-icon" />
            </a>

            <a
            // href=""
            >
              <FontAwesomeIcon icon={faTiktok} className="social-icon7" />
            </a>

            <a
            href="https://www.linkedin.com/company/twice-too-travel/"
            >
              <LinkedInIcon className="social-icon" />
            </a>

            <a
            href="https://www.instagram.com/twicetoo.travel?igsh=NDZoenp3MDNpNGx5&utm_source=qr"
            >
              <InstagramIcon className="social-icon" />
            </a>
            <a
            href="https://t.co/zbcBaWBPMj"
            >
              <XIcon className="social-icon" />
            </a>

            <a
            href="https://pin.it/6dK7MYZLw"
            >
              <PinterestIcon className="social-icon" />
            </a>
            
          </div>
          <div className="contactHolder">
            <div className="whatsapp-number">
              <WhatsAppIcon className="social-icon2" />
              <div className="text"> 078 3231 4946 </div>
            </div>

            <div className="whatsapp-number">
              <CallIcon className="social-icon2" />
              <div className="text">0203 5040 540 </div>
            </div>

            <div className="whatsapp-number">
              <MailOutlineIcon className="social-icon2" />
              <div className="text">info@twicetootravel.co.uk</div>
            </div>

            <div className="whatsapp-number">
              <AccessTimeIcon className="social-icon2" />
              <div className="text">Hours: Mon-Sat: 9am - 9pm</div>
            </div>
          </div>
        </div>
        <div className={isScrolling ? "lower-scrolling-class" : "lower"}>
          <div className="lower-inner">
            <div className="logo-upper-holder" onClick={handleHomeClick}>
              <img src={bgg} className="autobahn" />
            </div>

            <div className="navigations">
              <div
                className={
                  isScrolling
                    ? "navigation-item-scrolling-class"
                    : "navigation-item"
                }
                onClick={handleButtonClick}
              >
                Umrah
              </div>
              <div
                className={
                  isScrolling
                    ? "navigation-item-scrolling-class"
                    : "navigation-item"
                }
                onClick={handleHajjClick}
              >
                Hajj
              </div>
              <div
                className={
                  isScrolling
                    ? "navigation-item-scrolling-class"
                    : "navigation-item"
                }
                onClick={handleHolidayClick}
              >
                Holidays
              </div>

              {/* <div className="logoHolder">
                <img src={bgg} className="logo" onClick={handleHomeClick} />
              </div> */}

              <div
                className={
                  isScrolling
                    ? "navigation-item-scrolling-class"
                    : "navigation-item"
                }
                onClick={handleTestClick}
              >
                Testimonials
              </div>
              <div
                className={
                  isScrolling
                    ? "navigation-item-scrolling-class"
                    : "navigation-item"
                }
                onClick={handleContactClick}
              >
                Contact Us
              </div>
              <div className="navigation-item">
                <a
                  href={`https://wa.me/${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button text={"BOOK NOW"} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isScrollDisabled && (
        <div className="mobile-screen">
          <IoMenu className="menuIcon" onClick={toggleScroll} />
        </div>
      )}

      <img src={bgg} className="mobile-logo" onClick={handleHomeClick} />
      {isScrollDisabled && (
        <div className="navigator">
          <CloseRoundedIcon className="cross-icon" onClick={toggleScroll} />

          <div className="navi-inner">
            <div className="navi-logo-holder">
              <div className="logo-inner">
                <img src={bgg} className="navi-logo" />
              </div>
            </div>

            <div className="navi-buttons">
              <div className="navi-click" onClick={() => navigate("/")}>
                <img src={homeIcon} className="click-icon" />
                <div className="click-name">Home</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Umrah")}>
                <img src={UmrahIcon} className="click-icon" />
                <div className="click-name">Umrah</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Hajj")}>
                <img src={HajjIcon} className="click-icon" />
                <div className="click-name">Hajj</div>
              </div>

              <div className="navi-click" onClick={() => navigate("/Holiday")}>
                <img src={holidayIcon} className="click-icon" />

                <div className="click-name">Holiday</div>
              </div>

              <div
                className="navi-click"
                onClick={() => navigate("/Testimonials")}
              >
                <img src={testimonialIcon} className="click-icon" />
                <div className="click-name">Testimonials</div>
              </div>
              <div
                className="navi-click"
                onClick={() => navigate("/ContactUs")}
              >
                <img src={contactIcon} className="click-icon" />{" "}
                <div className="click-name">Contact Us</div>
              </div>
            </div>

            <div className="divider" />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 150px;
  width: 100%;
  position: fixed;
  z-index: 10;

  font-family: "Montserrat", sans-serif;

  .draw {
    position: absolute;
  }
  .optionpanel {
    position: absolute;
    height: 150px;
    width: 80%;
    bottom: -160px;
    z-index: 10;
    opacity: 0;
    transition: transform 0.3s ease;
    transition: opacity 0.3s ease;
  }
  .mobile-screen {
    display: none;
  }

  .lower {
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: none;
    padding-top: 10px;
    position: relative;
    transition: color 0.3s ease;
    transition: background-color 0.1s ease;
    margin-top: 1%;
  }
  .lower-scrolling-class {
    height: 130px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: none;
    padding-top: 10px;
    position: relative;
    transition: background-color 0.1s ease;
    background-color: whitesmoke;
    background-color: #f2f2f2;
  }
  .lower-inner {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .navigations {
    width: 100%;
    height: 150px;
    padding: 7px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: pink; */
    background-color: #083a59;
    border-radius: 50px;
    /* color: white; */
    /* border-top: 1px solid #010c2a; */

    /* background-color: red;/ */
  }
  .logo-upper-holder {
    height: 500px;
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .autobahn {
    height: auto;
    width: 500px;
    object-fit: cover;
  }

  .line-below {
    height: 2px;
    width: 100%;
    background-color: white;
    bottom: -10px;
    position: absolute;
    overflow: hidden;
    left: -25%;
    transition: left 0.2s ease-in-out;
    opacity: 0;
  }
  .active-line {
    height: 2px;
    width: 100%;
    bottom: -10px;
    position: absolute;
    overflow: hidden;
    transition: left 0.2s ease-in-out;
    opacity: 0;
    left: 0;
    opacity: 1;
  }
  .navigation-item:hover .line-below {
    left: 0;
    opacity: 0.75;
  }

  .navigation-item {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: white;
    position: relative;
    cursor: pointer;
    /* width: 10%; */
    text-align: center;
    text-transform: uppercase;
  }
  
  .navigation-item-scrolling-class {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: white;
    position: relative;
    cursor: pointer;
    width: 16%;
    text-align: center;
    text-transform: uppercase;
  }
  .arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 100%);
    height: 100px;
    width: 100px;
    background-color: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    opacity: 0;
    transition: transform 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .navigation-item:hover .arrow {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  .navigation-item:hover .arrow {
    display: block;
  }
  .lower-inner:hover .optionpanel {
    display: block;
    transform: translate(-10%, 0);
    opacity: 1;
  }
  .logoHolder {
    height: 100%;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .logo {
    height: 100px;
    width: 100%;
    object-fit: contain;
  }
  .logo:hover {
    cursor: pointer;
  }
  .topline {
    height: 45px;
    width: 100%;
    background-color: #010c2a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Montserrat", sans-serif;
  }
  .IconHolder {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: auto;
    padding-left: 2%;
    padding-right: 1%;
  }
  .contactHolder {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-right: 2%;
  }
  .whatsapp-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-left: 2px;
    margin-right: 2px;
    font-family: "Roboto", sans-serif;
  }
  .text {
    font-size: 15px;
    color: white;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
  }
  .social-icon {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-size: 20px;
    padding: 5px;
    border-radius: 50%;
  }

  .social-icon7 {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-size: 17px;
    padding: 5px;
    border-radius: 50%;
  }
  .social-icon3 {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    font-size: 20px;
    padding: 5px;
    height: 18px;
    width: 18px;
    margin-top: 5px;
  }
  .social-icon2 {
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    font-size: 20px;
  }
  .mobile-logo {
    display: none;
  }
  @media (max-width: 768px) {
    position: relative;
    height: 60px;
    background-color: white;
    margin-bottom: 0px;
    margin-top: 0px;

    .screen {
      display: none;
    }
    .mobile-screen {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
    }
    .menuIcon {
      color: #010c2a;
      font-size: 50px;
      margin-top: 4px;
      margin-right: 10px;
    }
    .mobile-logo {
      display: grid;
      position: absolute;
      height: auto;
      width: 200px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .drawer {
      background-color: black;
    }
    .navigator {
      position: absolute;
      background-color: white;
      height: 100vh;
      width: 100vw;
      background-color: #f2f2f2;
    }
    .cross-icon {
      position: absolute;
      right: 20px;
      top: 27px;
      font-size: 30px;
      color: #1328bc;
      z-index: 15;
      color: #010c2a;
    }
    .navi-inner {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .navi-logo-holder {
      height: 80px;
      width: 100%;
      margin-top: 2%;
      position: relative;
      display: flex;
      justify-content: start;
      align-content: center;
      flex-direction: row;
    }
    .logo-inner {
      height: 100%;
      width: 100%;
      margin: 2%;
      display: flex;
      justify-content: start;
      position: relative;
    }
    .navi-logo {
      height: 100px;
      width: 200px;
      margin-left: 7px;
      object-fit: contain;
    }

    .navi-buttons {
      height: auto;
      width: 100%;
      margin-top: 5%;
      position: relative;
    }
    .navi-click {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 2%;
      width: 45%;
      margin: 2%;
    }
    .click-icon {
      /* font-size: 35px; */
      height: 25px;
      width: 25px;
    }
    .click-name {
      font-size: 19px;
      width: 75%;
      font-weight: 500;
    }
    .divider {
      margin-top: 10%;
      height: 2px;
      width: 100%;
      background-color: #1328bc;
      opacity: 0.1;
    }
    .socials-navi {
      height: auto;
      margin-top: 5%;
      padding-left: 5%;
    }
    .navi-icon {
      font-size: 45px;
    }
    .navi-textholder {
      display: flex;
      align-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .navi-icon-holder {
      margin-top: 5%;
    }

    .icon2 {
      font-size: 25px;
      color: #1328bc;
    }
    .navi-text {
      font-size: 17px;
      font-weight: 500;
      margin-left: 5px;
    }
  }
`;
export default Header;
