import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UmrahPrompt from "../components/UmrahPrompt";
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

function Umrah() {
  const handleScroll = () => {
    window.scrollBy({
      top: 500,
      left: 0,
      behavior: "smooth",
    });
  };

  const Umrah1 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/21655581/pexels-photo-21655581/free-photo-of-a-crowd-standing-in-the-courtyard-of-a-mosque.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 799,
      title: "October Umrah Package",
      stars: 5,
      depart: "05 Oct 2024",
      arrival: "15 Oct 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/18351128/pexels-photo-18351128/free-photo-of-crowd-by-kaaba-in-masjid-al-haram-in-mecca-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 699,
      title: "February Umrah Package",
      stars: 5,
      depart: "10 Feb 2024",
      arrival: "24 Feb 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/19295595/pexels-photo-19295595/free-photo-of-passersby-on-a-sidewalk-near-medina-haram-piazza-occupied-by-a-flock-of-pigeons.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 899,
      title: "December Umrah Package",
      stars: 5,
      depart: "20 Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  const Umrah2 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/19021371/pexels-photo-19021371/free-photo-of-man-praying-in-muslim-temple.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 699,
      title: "October Umrah Package",
      stars: 4,
      depart: "12 Oct 2024",
      arrival: "22 Oct 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/19310992/pexels-photo-19310992/free-photo-of-people-walking-in-front-of-the-prophets-mosque-in-medina-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 599,
      title: "February Umrah Package",
      stars: 4,
      depart: "12 Feb 2024",
      arrival: "24 Feb 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/18351128/pexels-photo-18351128/free-photo-of-crowd-by-kaaba-in-masjid-al-haram-in-mecca-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 799,
      title: "December Umrah Package",
      stars: 4,
      depart: "20 Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  const Umrah3 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/15948385/pexels-photo-15948385/free-photo-of-woman-and-man-walking-in-traditional-clothing.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 599,
      title: "October Umrah Package",
      stars: 3,
      depart: "14 Oct 2024",
      arrival: "25 Oct 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/11934384/pexels-photo-11934384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 499,
      title: "February Umrah Package",
      stars: 3,
      depart: "13 Feb 2024",
      arrival: "26 Feb 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/18360300/pexels-photo-18360300/free-photo-of-pilgrims-in-grand-mosque-in-mecca.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 699,
      title: "December Umrah Package",
      stars: 3,
      depart: " Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <Wrapper>
      <Helmet>

     <meta name="description" content="Book your Umrah journey with Twice Too Travel and enjoy a seamless experience. Our affordable Umrah packages include visa assistance, comfortable accommodations, and guided tours for a memorable pilgrimage."/>
        <meta name="keywords" content="Umrah Packages, Umrah Pilgrimage, Affordable Umrah Trips, Umrah Travel Agency, Umrah Tour Packages, Umrah 2025 Packages, Umrah Visa Services, Umrah 2025 Services, Umrah Visa Processing, London Umrah Packages, Manchester Umrah Deals, Birmingham Umrah Travel, Umrah Packages from Glasgow, Affordable Umrah Tours Liverpool, Leicester Umrah Packages, Sheffield Umrah Deals, Custom Umrah Packages"/>
        </Helmet>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">
            Umrah
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <img
            className="bg-image"
            src="https://images.pexels.com/photos/19042360/pexels-photo-19042360/free-photo-of-prophets-mosque-building-in-medina-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          />
          <div className="blur-bg" />
        </div>
      </div>

      <UmrahPrompt title={"Explore 5 Star Packages"} data={Umrah1} />
<br/>
<br/>
<br/>
      <UmrahPrompt title={"Explore 4 Star Packages"} data={Umrah2} />
      <br/>
<br/>
<br/>
      <UmrahPrompt title={"Explore 3 Star Packages"} data={Umrah3} />

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }
  .blur-bg {
    height: 100%;
    width: 100%;
    background-color: grey;
    position: absolute;
    z-index: -1;
    opacity: 0.3;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 35px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 2%;
    color: #333333;
  }
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    /* font-weight: 100; */
    font-family: "Montserrat", sans-serif;
  }

  .upper-divs {
    height: 70vh;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    position: relative;
    background-color: #011526;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
      /* background-color: #1328bc; */
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default Umrah;
