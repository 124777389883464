import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CardHolder from "../components/CardHolder";
import LongCard from "../components/LongCard";
import CardHolder2 from "../components/CardHolder2";
import UmrahPrompt from "../components/UmrahPrompt";
import HolidayPrompt from "../components/HolidaysPrompt";
import HajjPrompt from "../components/HajjPrompt";
import Reviews from "../components/Reviews";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Helmet } from 'react-helmet';

import { Autoplay, Pagination, Navigation } from "swiper/modules";

import unitedAirline from '../unitedAirline.png'
import singapore from "../singaporeAirline.png"
import swiss from "../swissAirline.png"
import gulf from "../gulfAirline.png"
import emirates from "../emiratesAirline.png"
import british from "../britishAirways.png"
import turkish from "../turkishAirline.png"
import etihad from "../etihadAirline.png"
import saudiAirline from "../saudiAirline.png"
import airfrance from "../pngegg.png"
function Hero() {
  const phoneNumber = "+447476446541";

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  const airlines = [
    {
      id: 1,
      image:unitedAirline,
    
    },
    {
      id: 2,
      image:singapore
    },
    {
      id: 3,
      image:swiss
    },
    {
      id: 4,
      image:gulf
    },
    {
      id: 5,
      image:emirates
    },
    {
      id: 6,
      image:british
    },
    {
      id: 7,
      image:turkish
    },
    {
      id: 8,
      image:etihad
    },
    
    {
      id: 9,
      image:saudiAirline
    },
    {
      id: 9,
      image:airfrance
    }

    
  ];

  const Umrah1 = [
    {
      id: 1,
      image:
        "https://images.pexels.com/photos/21655581/pexels-photo-21655581/free-photo-of-a-crowd-standing-in-the-courtyard-of-a-mosque.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 799,
      title: "October Umrah Package",
      stars: 5,
      depart: "05 Oct 2024",
      arrival: "15 Oct 2024",
    },
    {
      id: 2,
      image:
        "https://images.pexels.com/photos/18351128/pexels-photo-18351128/free-photo-of-crowd-by-kaaba-in-masjid-al-haram-in-mecca-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 699,
      title: "February Umrah Package",
      stars: 5,
      depart: "10 Feb 2024",
      arrival: "24 Feb 2024",
    },
    {
      id: 3,
      image:
        "https://images.pexels.com/photos/19295595/pexels-photo-19295595/free-photo-of-passersby-on-a-sidewalk-near-medina-haram-piazza-occupied-by-a-flock-of-pigeons.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 899,
      title: "December Umrah Package",
      stars: 5,
      depart: "20 Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  const Hajj = [
    {
      id: 1,
      image:
        "https://images.pexels.com/photos/18360300/pexels-photo-18360300/free-photo-of-pilgrims-in-grand-mosque-in-mecca.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 6999,
      title: "Premium Hajj Package",
      stars: 5,
      depart: "01 Jun 2024",
      arrival: "18 Jun 2024",
    },
    {
      id: 2,
      image:
        "https://images.pexels.com/photos/19310992/pexels-photo-19310992/free-photo-of-people-walking-in-front-of-the-prophets-mosque-in-medina-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 5999,
      title: "Non Shifting Package",
      stars: 5,
      depart: "08 Jun 2024",
      arrival: "20 Jun 2024",
    },
    {
      id: 3,
      image:
        "https://images.pexels.com/photos/19021371/pexels-photo-19021371/free-photo-of-man-praying-in-muslim-temple.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 4999,
      title: "Shifting Hajj Package",
      stars: 5,
      depart: "10 Jun 2024",
      arrival: "24 Jun 2024",
    },
  ];

  const Holiday = [
    {
      id: 1,
      image:
        "https://images.pexels.com/photos/2159549/pexels-photo-2159549.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 449,
      title: "Turkey Halal Escape",
      stars: 5,
      depart: "25 Jul 2024",
      arrival: "05 Aug 2024",
    },
    {
      id: 2,
      image:
        "https://images.pexels.com/photos/3601426/pexels-photo-3601426.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 999,
      title: "Maldives Luxury Halal Retreat",
      stars: 5,
      depart: "10 Aug 2024",
      arrival: "24 Aug 2024",
    },
    {
      id: 3,
      image:
        "https://images.pexels.com/photos/1703312/pexels-photo-1703312.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 499,
      title: "Moroccan Cultural Adventure",
      stars: 5,
      depart: "10 Oct 2024",
      arrival: "20 Oct 2024",
    },
  ];



  return (
    <Wrapper>
      <Header />
      <div className="outer">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          // navigation={true}
          loop={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide className="swiper-slice">
            <div className="holder">
              <img
                src={
                  "https://images.pexels.com/photos/20277838/pexels-photo-20277838/free-photo-of-prophet-mosque-in-medina-in-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
                className="bg-image"
              />
              <img
                src={
                  "https://images.pexels.com/photos/20277838/pexels-photo-20277838/free-photo-of-prophet-mosque-in-medina-in-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
                className="bg-mobile"
              />

              <div className="swiper-holder">
                <div className="main-text">
                  Find spiritual
                  <br />
                  tranquility with our
                  <span className="bold"> Umrah</span> and Hajj packages.
                </div>

                <div className="main-text2">
                  Find spiritual
                  <br />
                  tranquility with our
                  <span className="bold"> Umrah</span> and
                  <span className="bold"> Hajj</span>
                  <br /> packages.
                </div>
                <a
                  href={`https://wa.me/${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="holder">
              <img
                src={
                  "https://images.unsplash.com/photo-1518317507427-5346735246ab?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D387-422e29b40080?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D583-7375336cde4b?q=80&w=1630&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                className="bg-image"
              />
              <img
                src={
                  "https://images.unsplash.com/photo-1569839333583-7375336cde4b?q=80&w=1630&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                className="bg-mobile"
              />

              <div className="swiper-holder">
                <div className="main-text">
                  Find Cheap
                  <br />
                  flights from
                  <span className="bold"> Anywhere</span> to Anywhere.
                </div>

                <div className="main-text2">
                  Find Cheap flights
                  <br />
                  from
                  <span className="bold"> Anywhere</span> to Anywhere.
                </div>
                <a
                  href={`https://wa.me/${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="holder">
              <img
                src={
                  "https://images.pexels.com/photos/1167021/pexels-photo-1167021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
                className="bg-image"
              />
              <img
                src={
                  "https://images.pexels.com/photos/1167021/pexels-photo-1167021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                }
                className="bg-mobile"
              />

              <div className="swiper-holder">
                <div className="main-text">
                  Find Affordable
                  <br />
                  holiday packages
                  <span className="bold"> from Anywhere</span> to Everywhere.
                </div>

                <div className="main-text2">
                  Find Affordable
                  <br />
                  holiday packages
                  <span className="bold"> from Anywhere</span> to Everywhere.
                </div>
                <a
                  href={`https://wa.me/${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="second-div">
        <div className="titletext">
          Rely on our travel experts for a smooth trip, managed with precision
          and expertise.
        </div>
      </div>

      <CardHolder />

      <LongCard
        buttontext={"Speak with Expert"}
        image={
          "https://images.pexels.com/photos/11934382/pexels-photo-11934382.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
        maintext={
          "Looking for a smooth, worry-free travel experience? We’re here to help!"
        }
        subtext={
          "Contact us today to discover our customized travel options and begin planning your perfect trip effortlessly!"
        }
        link={"/ContactUs"}
      />

      <CardHolder2 />
      <LongCard
        buttontext={"Contact Us"}
        image={
          "https://images.unsplash.com/photo-1697463624716-cd2f0423d9d9?q=80&w=1643&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
        maintext={"Give us a call"}
        subtext={
          "If you have an enquiry relating to any of our packages, products or services, please get in touch to speak to someone in our office and we’ll be happy to assist you."
        }
        link={"/ContactUs"}
      />
      <UmrahPrompt title={"Umrah Packages"} data={Umrah1} />
      <HajjPrompt title={"Hajj Packages"} data={Hajj} />
      <HolidayPrompt title={"Holidays Packages"} data={Holiday} />
      <Reviews />

      <div className="airline-swiper-holder">
      <Swiper
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={{
          768: {
            slidesPerView: 5,
          },
        }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]} 
        loop={true}
        className="mySwiper2"
      >
        {airlines.map((user) => (
              <SwiperSlide key={user.id} className="airline-slide">
                <img src={user.image} className="swiper-airline"/>  
              </SwiperSlide>
            ))}
      </Swiper>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  font-family: "Montserrat", sans-serif;

  .mySwiper {
    height: 100%;
    width: 100%;
  }
  .loader-screen {
    height: 100%;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 1s ease-in-out;
  }

  .swiper-airline{
    /* height: 180px; */
    object-fit: contain;
    height:60px;
  }

  .airline-swiper-holder{
    height: auto;
    background-color: #f2f2f2;
    padding-bottom:10%;
  }
  .airline-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 150px;
  }

  .loader-icon.hidden {
    opacity: 0;
  }
  .loader-icon {
    width: 300px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotate 5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .second-div {
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
  }
  .titletext {
    margin-top: 15px;
    font-style: italic;
    font-size: 18px;
    color: #333333;
  }
  .swiper-holder {
    position: absolute;
    height: 75%;
    width: 100%;
    top: 0px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button {
    color: white;
    width: 165px;
    font-family: "Montserrat", sans-serif;
    margin-top: 15px;
    transition: color 0.3s ease;
  }
  .button #hover {
    cursor: pointer;
  }
  .icon {
    margin-right: 5px;
  }
  .bold {
    font-weight: 500;
  }
  .main-text {
    font-size: 55px;
    font-family: "Roboto", sans-serif;
    color: white;
    width: 55%;
    line-height: 47px;
    margin-bottom: 2%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-top: 5%;
    font-family: "Barlow", sans-serif;
  }

  .holder {
    height: 100%;
    width: 100%;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    /* background-color: #1328bc; */
  }
  .outer {
    height: 100%;
    width: 100%;
  }

  .blur-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
  }
  .bg-image {
    height: 142.857%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .bg-image2 {
    height: 100%;
    width: 100%;
    object-fit: fit;
    z-index: -1;
    position: absolute;
  }
  .bg-mobile {
    display: none;
  }
  .main-text2 {
    display: none;
  }
  @media (max-width: 768px) {
    height: 80vh;

    .main-text {
      display: none;
    }
    .main-text2 {
      display: inline;
      font-family: "Roboto", sans-serif;
      color: white;
      width: 55%;
      line-height: 47px;
      margin-bottom: 2%;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-size: 24px;
      width: 85%;
    }
    .titletext {
      margin-top: 11px;
      font-size: 15px;
      text-align: center;
    }
    .second-div {
      height: 10vh;
      background-color: #f2f2f2;
    }
    .bg-image {
      display: none;
    }
    .holder {
      height: 100%;
      width: 100%;
      position: relative;
      /* background-color: #1328bc; */
      clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    }
    .outer {
      height: 100%;
      width: 100%;
      background-color: #f2f2f2;
      top: 0px;
    }
    .bg-mobile {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      bottom: -10%;
      right: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    }
  }
`;

export default Hero;
