import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HolidayPrompt from "../components/HolidaysPrompt"
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';


function HalalHoliday() {

  const handleScroll = () => {
    window.scrollBy({
      top: 600,
      left: 0,
      behavior: "smooth",
    });
  };

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);


  const Umrah1 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/2159549/pexels-photo-2159549.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 449,
      title: "Turkey Halal Escape",
      stars: 5,
      depart: "25 Jul 2024",
      arrival: "05 Aug 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/3601426/pexels-photo-3601426.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 999,
      title: "Maldives Luxury Halal Retreat",
      stars: 5,
      depart: "10 Aug 2024",
      arrival: "24 Aug 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/1703312/pexels-photo-1703312.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice:499,
      title: "Moroccan Cultural Adventure",
      stars: 5,
      depart: "10 Oct 2024",
      arrival: "20 Oct 2024",
    },
  ];

  const Umrah2 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/1388030/pexels-photo-1388030.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 399,
      title: " Spain Halal Heritage Tour",
      stars: 4,
      depart: "25 Jul 2024",
      arrival: "05 Aug 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/22804/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 999,
      title: " Malaysia Family Halal Holiday",
      stars: 4,
      depart: "10 Aug 2024",
      arrival: "24 Aug 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/4388167/pexels-photo-4388167.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice:599,
      title: " Jordan Historical Journey",
      stars: 4,
      depart: "10 Oct 2024",
      arrival: "20 Oct 2024",
    },
  ];

  const Umrah3 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/25662156/pexels-photo-25662156/free-photo-of-motorboats-on-sea-coast.jpeg?auto=compress&cs=tinysrgb&w=600",
      startingPrice: 499,
      title: "Antalya Family Package",
      stars: 3,
      depart: "10 Jun 2024",
      arrival: "24 Jun 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/16535883/pexels-photo-16535883/free-photo-of-ancient-egyptian-ruins-of-luxor-temple.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 699,
      title: "Cairo Exploration Package",
      stars: 3,
      depart: "13 Feb 2024",
      arrival: "26 Feb 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/19328486/pexels-photo-19328486/free-photo-of-view-of-the-blue-mosque-in-istanbul-turkey.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 399,
      title: "Turkey Journey Package",
      stars: 3,
      depart: " Dec 2024",
      arrival: "30 Dec 2024",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
     <meta name="description" content="Twice Too Travel offers exceptional Hajj, Umrah, and Hala tour packages. Explore exciting global destinations with our all-inclusive travel options."/>
     </Helmet>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">

          Holidays
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <img className="bg-image" src="https://images.pexels.com/photos/625409/pexels-photo-625409.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"/>
            <div className="blur-bg"/>
        </div>

      </div>

      <HolidayPrompt title={"Explore 5 Star Holiday Packages"} data={Umrah1}/>

      <HolidayPrompt title={"Explore 4 Star Holiday Packages"} data={Umrah2}/>

      <HolidayPrompt title={"Explore 3 Star Holiday Packages"} data={Umrah3}/>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .bg-image{
    height: 100%;
    width:100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;

  }
  .blur-bg{
    height: 100%;
    width: 100%;
    background-color: grey;
    position: absolute;
    z-index: -1;
    opacity: 0.3;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 35px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 2%;
    color: #333333;
  }
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    /* font-weight: 100; */
    font-family: "Montserrat", sans-serif;

  }

  .upper-divs {
    height: 70vh;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color:#1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
    }
  .down-icon:hover {
    cursor: pointer;
    background-color:white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);

  }
  .holders {
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    position: relative;
    background-color: #011526;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default HalalHoliday;
