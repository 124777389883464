import styled from "styled-components";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

function HolidayCard(props) {
  
  const phoneNumber = "447832314946";

  return (
    <Wrapper>
      <div className="outer">
        <div className="image-holder">
          <img src={props.image} className="card-image" />
        </div>

        <div className="info-holder">
          <div className="info-holder-inner">
            <div className="info-title">{props.title}</div>

            <div className="days-night">
              <div className="days-info">4 Days</div>
              <div className="divider"></div>
              <div className="days-info">4 Nights</div>
            </div>

            <div className="add-ons">
              <div className="addon-item">
                <FlightTakeoffIcon className="addon-icon" />
                <div className="addon-title">Flight</div>
              </div>

              <div className="addon-item">
                <ApartmentIcon className="addon-icon" />
                <div className="addon-title">Hotel</div>
              </div>

              <div className="addon-item">
                <DirectionsCarIcon className="addon-icon" />
                <div className="addon-title">Transfer</div>
              </div>

              <div className="addon-item">
                <DocumentScannerIcon className="addon-icon" />
                <div className="addon-title">Visa</div>
              </div>
            </div>

            <div className="major-divider" />
          </div>
        </div>
        <br />

        <div className="action-section">
          <div className="action-section-inner">
            <div className="price-section">
              <div className="start-title">STARTING FROM</div>

              <div className="price-amount">
                {props.startingPrice}
                <span className="currency">GBP</span>
              </div>
            </div>

            <div className="know-section">
            <a
                  href={`https://wa.me/${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none"}}
                >
                   <div className="more-button">KNOW MORE</div>

                </a>
             
              
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 31%;
  height: 580px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 10%;
  margin-top: 10%;

  .outer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    align-content: center;
  }

  .more-button {
    height: auto;
    width: auto;
    background-color: #f2ae30;
    padding: 4%;
    border-radius: 15px;
    font-weight: 600;
    color:black;
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .price-amount {
    font-size: 35px;
    margin-top: 10%;
  }

  .currency {
    vertical-align: sub;
    font-size: 12px;
  }
  .price-section {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    align-content: center;
  }
  .know-section {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .start-title {
    font-size: 13px;
  }

  .action-section {
    width: 100%;
    height: 22%;
    border-radius: 0px 0px 20px 20px;
  }
  .major-divider {
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, #f2ae30, rgba(255, 0, 0, 0));
  }
  .add-ons {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 80%;
  }

  .info-holder-inner {
    height: 100%;
    width: auto;
    margin-left: 5%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .action-section-inner {
    height: 100%;
    width: auto;
    margin-left: 5%;
    border-radius: 0px 0px 20px 0px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
  }

  .addon-icon {
    font-size: 35px;
    color: #010c2a;
  }

  .addon-title {
    font-size: 13px;
    color: #010c2a;
  }

  .days-info {
    font-size: 12px;
  }

  .addon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .days-night {
    width: 40%;
    height: 17px;
    border-radius: 10px;
    border: 1px solid #010c2a;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .divider {
    height: 100%;
    width: 2px;
    background-color: #010c2a;
  }

  .info-title {
    font-size: 29px;
    color: #010c2a;
  }

  .image-holder {
    height: 40%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
  .info-holder {
    height: 38%;
    width: 100%;
  }
  .card-image {
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    width: 90%;
    
  }
`;
export default HolidayCard;
