import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HajjPrompt from "../components/HajjPrompt";
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

function Hajj() {
  const handleScroll = () => {
    window.scrollBy({
      top: 600,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const Umrah1 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/18360300/pexels-photo-18360300/free-photo-of-pilgrims-in-grand-mosque-in-mecca.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 6999,
      title: "Premium Hajj Package",
      stars: 5,
      depart: "01 Jun 2024",
      arrival: "18 Jun 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/19310992/pexels-photo-19310992/free-photo-of-people-walking-in-front-of-the-prophets-mosque-in-medina-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 5999,
      title: "Non Shifting Package",
      stars: 5,
      depart: "08 Jun 2024",
      arrival: "20 Jun 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/19021371/pexels-photo-19021371/free-photo-of-man-praying-in-muslim-temple.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 4999,
      title: "Shifting Hajj Package",
      stars: 5,
      depart: "10 Jun 2024",
      arrival: "24 Jun 2024",
    },
  ];

  const Umrah2 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/19824782/pexels-photo-19824782/free-photo-of-illuminated-minaret-of-prophet-mosque.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 4999,
      title: "Comfort Hajj Package",
      stars: 5,
      depart: "01 Jun 2024",
      arrival: "18 Jun 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/8059446/pexels-photo-8059446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 4999,
      title: "Silver Hajj Package",
      stars: 4,
      depart: "08 Jun 2024",
      arrival: "20 Jun 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/8059445/pexels-photo-8059445.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 3599,
      title: "Budget Hajj Package",
      stars: 3,
      depart: "10 Jun 2024",
      arrival: "24 Jun 2024",
    },
  ];

  const Umrah3 = [
    {
      id: 1,
      image: "https://images.pexels.com/photos/8059351/pexels-photo-8059351.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 3999,
      title: "Budget Hajj Package",
      stars: 5,
      depart: "01 Jun 2024",
      arrival: "18 Jun 2024",
    },
    {
      id: 2,
      image: "https://images.pexels.com/photos/12765598/pexels-photo-12765598.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 3999,
      title: "Economy Hajj Package",
      stars: 4,
      depart: "08 Jun 2024",
      arrival: "17 Jun 2024",
    },
    {
      id: 3,
      image: "https://images.pexels.com/photos/15920832/pexels-photo-15920832/free-photo-of-a-religious-temple-in-saudi-arabia.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      startingPrice: 3999,
      title: "Economy Hajj Package",
      stars: 3,
      depart: "10 Jun 2024",
      arrival: "24 Jun 2024",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <meta name="description" content="Discover affordable Hajj packages for 2025 with Twice Too Travel. Join our expertly guided pilgrimage to Saudi Arabia, and experience a safe, spiritual journey tailored to your needs."/>
        <meta name="keywords" content="Hajj Packages, Hajj Pilgrimage, Affordable Hajj Trips, Hajj Travel Agency, Hajj Tour Packages, Hajj 2025 Packages, Hajj Visa Services, Hajj 2025 Services, Hajj Visa Processing, London Hajj Packages, Manchester Hajj Deals, Birmingham Hajj Travel, Hajj Packages from Glasgow, Affordable Hajj Tours Liverpool, Leicester Hajj Packages, Sheffield Hajj Deals, Custom Hajj Packages"/>
        </Helmet>
      <Header />
      <div className="upper-divs">
        <div className="holders">
          <div className="large-text">
           Hajj
          </div>
          <KeyboardArrowDownIcon className="down-icon" onClick={handleScroll} />
          <img
            className="bg-image"
            src={
              "https://images.pexels.com/photos/7042325/pexels-photo-7042325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
          />
          <div className="blur-bg" />
        </div>
      </div>

      <HajjPrompt title={"Explore 5 Star Packages"} data={Umrah1} />

      <HajjPrompt title={"Explore Non Shifting Packages"} data={Umrah2} />

      <HajjPrompt title={"Explore  Shifting Packages"} data={Umrah3} />

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;

  .bg-image {
    height: 100%;
    width: 100%;
    z-index: -2;
    position: absolute;
    object-fit: cover;
  }
  .blur-bg {
    height: 100%;
    width: 100%;
    background-color: grey;
    position: absolute;
    z-index: -1;
    opacity: 0.3;
  }
  .package-holder {
    height: 100vh;
    width: 100%;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 35px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 2%;
    color: #333333;
  }
  .large-text {
    font-size: 35px;
    width: 70%;
    text-align: center;
    color: white;
    margin-top: 7.5%;
    font-family: "Montserrat", sans-serif;
  }

  .upper-divs {
    height: 70vh;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  }
  .down-icon {
    font-size: 75px;
    margin-top: 45px;
    color: white;
    background-color: #1328bc;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  .down-icon:hover {
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    color: #1328bc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  .holders {
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    position: relative;
    background-color: #011526;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .upper-divs {
      height: 70vh;
      width: 100%;
      clip-path: none;
    }

    .holders {
      clip-path: none;
      /* background-color: #1328bc; */
    }

    .large-text {
      font-size: 20px;
      width: 95%;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
export default Hajj;
