import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ReviewCard from "./ReviewCard";

function Reviews() {
  const navigate = useNavigate();
  const users = [
    { id: 1,category:"UMRAH",avatar:true, name: " Aqeel H",date:"January",star:5,comment:"I booked my flight from Shoaib, both were so polite and helpful. My journey was totally hassle free. I will book my journey again with Twice Too Travel. Highly recommended." },
    { id: 2,category:"UMRAH",avatar:true, name: " Abdulrehman B",date:"January",star:5,comment:"I booked my Umrah from Twice Too Travel for whole family and they gave me the best package with almost 5 star facilities. Thanks to Shoaib." },
    { id: 3,category:"UMRAH",avatar:false, name: "Sumra U",date:"Feburary",star:5,comment:"Fantastic experience. Thank you shoaib it was really a most memorable trip . The journey from Lahore to London was really smooth." },
    { id: 4,category:"UMRAH",avatar:true, name: "Abdul W",date:"Feburary",star:5,comment:"Mr. Shoaib, in particular, deserves special commendation for his exceptional service. He went above and beyond to secure the most economical air tickets, ensuring we got the best value for our money. His attention to selecting comfortable hotels and arranging seamless transport, making the entire trip smooth and hassle-free. The professionalism and dedication displayed by Mr. Shoaib and the team at Twice Too Travel greatly contributed to a memorable and spiritually fulfilling Umrah journey. I highly recommend their services for anyone planning their pilgrimage." },
   
    { id: 5,category:"UMRAH",avatar:false, name: "Martin",date:"Feburary",star:5,comment:"I enjoyed my travel with Twice Too Travel. Highly recommended. Hats of to Shaoib" },
    { id: 6,category:"UMRAH",avatar:true, name: "Ashar Khan",date:"March",star:5,comment:"Booked my journey through Shoaib, good experience overall." },
    { id: 7,category:"UMRAH",avatar:false, name: "Shahbaz B",date:"March",star:5,comment:"Shoaib has provided excellent service .Recommended to everyone ." },
    { id: 8,category:"UMRAH",avatar:true, name: "Abdal M",date:"March",star:5,comment:"By the grace of Allah SWT we were blessed to come to Makkah and perform umrah as a group of 10.During our journey, we experienced many beautiful memories and made new memories too.It is with a heavy heart that we will soon be departing Makkah for Medina, however we would like to say that your help and support from the start to the end of this experience, has been truly wonderful. We, as a group of 10, pray sincerely that your business of facilitating people to be able to perform umrah/hajj expand and grow with good means. May Allah SWT bless you and your team abundantly and keep good away from evil, while keeping everyone in good health.May Allah SWT give dawah to everyone to come and experience this magic."},
    { id: 9,category:"UMRAH",avatar:false, name: "Bala",date:"March",star:5,comment:"Exceptional Service! Amazing Shoaib! Thank you! The service provided by Twice Too Travel is truly exceptional! After searching for a reliable agency for two to three weeks, I finally found a trustworthy partner in Shoaib. Not only did he answer all of my questions, but he also managed to complete everything within a day - an amazing feat. I highly recommend Twice Too Travel if you're planning a pilgrimage trip, as their efforts went above and beyond my expectations." },
    { id: 10,category:"UMRAH",avatar:true, name: "Komal A",date:"April",star:5,comment:"Excellent Service :Excellent Service :Shoaib from Twice Too Travel Advisor provided the best prices for tickets and visa. His recommendation will undoubtedly be valuable to anyone seeking travel assistance. Quick Response . I would recommend to everyone." },
    { id: 11,category:"UMRAH",avatar:false, name: "Maulooda C",date:"April",star:5,comment:"The best price and service which no one else can match I booked my tickets from shuaib Ali and he was able to get me the best prices for the best possible trip. He also helped us a lot along the way through any issues and was a constant support, helping be able to plan the perfect trip . Thank you Twice Too Travel and thank you Shoaib Ali 🌸" },
    { id: 12,category:"UMRAH",avatar:true, name: "Abisola M",date:"May",star:5,comment:"Great customer service, always kind, polite and helpful. I keep using Twice Too Travel always. I also keep referring Shoaib to friends and family." },  
   
  ];
  return (
    <Wrapper>
      <div className="title">Testimonials</div>
      <div className="main">
        <div className="leftblur"></div>
        <div className="rightblur"></div>

        <div className="cardholder">
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={30}
           
            navigation={true} // Enable navigation arrows
            modules={[Pagination]}
            className="mySwiper"
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
            }}
          >

{users.map((user) => (
            <SwiperSlide key={user.id}>
              {" "}
              <ReviewCard key={user.id} category={user.category} avatar={user.avatar} date={user.date} star={user.star} comment={user.comment} name={user.name} />
              </SwiperSlide>
          ))}

        
          </Swiper>
        </div>

        <div className="button" onClick={() => navigate("/Testimonials")}>
          <div className="buttontext">READ MORE</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  position: relative;

 
  .buttontext {
    text-align: center;
    font-weight: normal;
    letter-spacing: 2px;
    transition: all 0.3s;

  }
  .button {
    height: 13px;
    width: 170px;
    padding: 1%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all 0.3s;
    transition: border 0.1s;
    background-color:  #F2AE30;
  }
  .button:hover .buttontext {
    cursor: pointer;
}
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 5%;
    z-index: 5;
  }

  .leftblur {
    height: 50%;
    width: 15%;
    background-color: white;
    position: absolute;
    left: 0px;
    z-index: 10;
    filter: blur(130px);
  }
  .rightblur {
    height: 50%;
    width: 15%;
    background-color: white;
    position: absolute;
    right: 0px;
    z-index: 10;
    filter: blur(150px);
  }
  .title {
    color: #454a46;
    text-align: center;
    font-family: "EB Garamond", serif;
    letter-spacing: 2px;
    padding-top: 5%;
    font-weight: 600;
    font-size: 35px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #333333;
  }
  .cardholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide {
    width: 60%;
  }
  @media (max-width: 768px) {
    padding-bottom: 20%;
    height: 100%;
    .button{
      height: 50px;
      width: 180px;
      border-radius: 50px;
      
    }

  }
`;
export default Reviews;