import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { faSnapchat } from "@fortawesome/free-brands-svg-icons";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { FaFacebookSquare } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

import ssl from "../ssl.png"
import trustpilot from "../trustpilot.png"
import mastercard from "../mastercard.png"
import iata from "../iata.png"

import visa from "../visa.png"
import PinterestIcon from '@mui/icons-material/Pinterest';
import XIcon from '@mui/icons-material/X';


import gurantee from "../kisspng-money-back-guarantee-service-business-100-guaranteed-5b49005a142f40.9236505915315108740827.png"

const Footer = () => {
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  return (
    <Wrapper>
      <div className="upper">
        <div className="topdiv">
          <div className="IconHolder">
            <a

            href="https://x.com/TwiceTooTravel"
            >
              <XIcon className="social-icon44" />
            </a>

            <a href="https://www.facebook.com/share/oyyhaEMF43htcbXZ/?mibextid=LQQJ4d">
              <FaFacebookSquare icon={faSnapchat} className="social-icon44" />
            </a>

            <a
            href="https://www.tiktok.com/@TwiceTooTravel?_t=8on37v8pbAO&_r=1"
            >
              <FontAwesomeIcon icon={faTiktok} className="social-icon55" />
            </a>
            <a

            href="https://www.instagram.com/TwiceTooTravel?igsh=dWJodnJ3ODM4NDBn"
            >
              <InstagramIcon className="social-icon" />
            </a>
            <a
            href="https://www.linkedin.com/company/twice-too-travel/"
            >
              <LinkedInIcon className="social-icon" />
            </a>

            <a
            href="https://pin.it/6dK7MYZLw"
            >
              <PinterestIcon className="social-icon" />
            </a>
          </div>
          <div className="navi-holder">
            <div className="navi-button" onClick={() => navigate("/Umrah")}>
              Umrah
            </div>
            <div className="navi-button" onClick={() => navigate("/Hajj")}>
              Hajj
            </div>
            <div className="navi-button" onClick={() => navigate("/Holiday")}>
              Holidays
            </div>
            <div
              className="navi-button"
              onClick={() => navigate("/Testimonials")}
            >
              Testimonials
            </div>

            <div className="navi-button" onClick={() => navigate("/ContactUs")}>
              Contact Us
            </div>
          </div>
        </div>

        <div className="logos-cabin">
              <img src={ssl} className="logo-image"/>
              <img src={mastercard} className="logo-image"/>
              <img src={"https://images.ctfassets.net/irb02qhvk7z1/7z28vVPye6sCSzxSgmLaKn/c1beb9d75a7ddba3272dbf850496cac6/TP-dark.png"} className="logo-image"/>
            

              <img src={gurantee} className="logo-image"/>
              <img src={visa} className="logo-image"/>
            

            
        </div>

        <div className="lowerdiv">
          <div className="paragraph">
          TwiceTooTravel is a leading travel agency focusing on Umrah, Hajj, and Holidays. We offer all-inclusive packages, taking care of everything from visas to accommodations, guaranteeing a smooth and spiritually fulfilling experience for the community.
          </div>
          <div className="paragraph2">
          TwiceTooTravel offers customized holiday packages to a range of destinations, featuring certified accommodations and unique activities. Whether you're looking for a relaxing beach vacation, an adventurous safari, or a cultural tour, TwiceTooTravel ensures a travel experience that meets your preferences and expectations.          </div>

          <div className="paragraph3">
          82 Redlake Drive, Taunton, Somerset, United Kingdom, TA1 2RS Twice
            <br />
            Twice Too Travel © 2024
          </div>

          <div className="paragraph4">
            82 Redlake Drive, Taunton, Somerset, United Kingdom, TA1 2RS Twice
            Too Travel © 2024 All Rights Reserved.
          </div>
        </div>
      </div>

      <div className="copyright"></div>
 </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 650px;
  width: 100%;
  padding-top: 5%;
  background-color: #f2f2f2;
  position: relative;

  font-family: "Montserrat", sans-serif;

  .scroll-icon {
    position: absolute;
    bottom: 5%;
    right: 5%;
    font-size: 50px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 50%;
    color: #1328bc;
  }
  .scroll-icon:hover {
    cursor: pointer;
  }
  
  .logos-cabin{
  
    width: 75%;
    margin-top:2.5%;
    margin-bottom:2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
  }

  .logo-image{
    height: 50px;
    width: auto;
  }

  .lowerdiv {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
  }
  .upper {
    height: 100%;
    background-color: #010c2a;
    clip-path: polygon(
      50% 6%,
      100% 0,
      100% 60%,
      100% 100%,
      0 100%,
      0% 60%,
      0 12%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .paragraph2 {
    margin-top: 3%;
    color: white;
    font-size: 13px;
  }
  .paragraph3 {
    display: inline;
    margin-top: 5%;
    color: white;
    font-size: 12px;
    text-align: center;
    display: none;
  }
  .paragraph4 {
    display: inline;
    margin-top: 5%;
    color: white;
    font-size: 12px;
    text-align: center;
  }
  .paragraph {
    font-size: 13px;
    color: white;
  }
  .navi-holder {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .IconHolder {
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .social-icon {
    font-size: 60px;
    color: white;
  }
  .social-icon3 {
    font-size: 60px;
    color: white;
    height: 50px;
    width: 50px;
  }
  .social-icon44 {
    font-size: 60px;
    color: white;
    height: 50px;
    width: 50px;
  }
  .social-icon55 {
    font-size: 60px;
    color: white;
    height: 50px;
    width: 50px;
  }
  .navi-button {
    font-size: 20px;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }
  .navi-button:hover {
    cursor: pointer;
  }

  .topdiv {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .privacy {
    width: 100%;
    height: 25px;
    background-color: #f2f2f2;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .privacyText {
    color: grey;
    letter-spacing: 5px;
    text-align: center;
  }
  .links {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .link {
    display: flex;
    padding: 10px;
    flex-direction: row;
    margin: 1%;
  }
  .link:hover {
    cursor: pointer;
  }
  .arrowIcon {
    color: white;
    opacity: 0.65;
  }
  .linkText {
    font-size: 18px;
    color: white;
  }
  .sublist {
    width: 20%;
  }
  .head {
    font-size: 30px;
    color: #ecffff;
    font-family: "EB Garamond", serif;
  }
  .divider {
    height: 1px;
    background-color: white;
    opacity: 0.65;
  }

  @media (max-width: 768px) {
    height: 750px;

    .logos-cabin{
    width: 100%;
    margin-top:7%;
    margin-bottom:2.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .logo-image{
    height: 30px;
    width: auto;
  }

    .paragraph4 {
      display: none;
    }
    .social-icon3 {
      height: 20px;
      width: 20px;
      background-color: #c7d8ff;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      margin-bottom: 0px;

      margin-top: 2px;
    }
    .social-icon55 {
      height: 20px;
      width: 20px;
      background-color: white;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      margin-left: 5px;
      margin-bottom: 3px;
    }
    .social-icon44 {
      height: 20px;
      width: 20px;
      background-color: #c7d8ff;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      background-color: white;

      margin-left: 5px;
      margin-bottom: 0px;
    }
    .navi-button {
      font-size: 17px;
      color: white;
      font-weight: normal;
      margin-top: 15px;
      font-family: "Montserrat", sans-serif;
    }
    .paragraph3 {
      display: inline;
      margin-top: 15%;
      color: white;
      font-size: 12px;
      text-align: center;
    }
    .topdiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .navi-holder {
      width: 90%;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-top: 35px;
    }
    .IconHolder {
      width: 90%;
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }

    .upper {
      height: 100%;
      clip-path: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .social-icon {
      font-size: 20px;
      background-color: #c7d8ff;
      border-radius: 50%;
      padding: 5px;
      color: #0f1762;
      background-color: white;
      margin-left: 5px;
    }
    .lowerdiv {
      width: 90%;
      margin-top: 10%;
    }
    .privacy {
      display: none;
    }
    .privacyText {
      font-size: 11px;
      padding: 3%;
    }
    .scroll-icon {
      font-size: 40px;
    }
  }
`;

export default Footer;
