import styled from "styled-components";
import HolidayCard from "./HolidayCard";
import { Swiper, SwiperSlide } from "swiper/react";


function HolidayPrompt(props) {

  const users = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "John Doe" },
   
  ];
  return (
    <Wrapper>
      <div className="bg-style">

      </div>
        <div className="inner">
        <div className="maintext">{props.title}</div>
        <div className="cardholder">
          {props.data.map((user) => (
         
              <HolidayCard
                key={user.id}
                image={user.image}
                startingPrice={user.startingPrice}
                title={user.title}
                stars={user.stars}
                depart={user.depart}
                arrival={user.arrival}
              />
          ))}
        </div>
        <div className="swiperholder">
          <Swiper
            className="mySwiper"
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
          >
            {props.data.map((user) => (
              <SwiperSlide key={user.id}>
                <HolidayCard
                  key={user.id}
                  image={user.image}
                  startingPrice={user.startingPrice}
                  title={user.title}
                  stars={user.stars}
                  depart={user.depart}
                  arrival={user.arrival}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`

  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding-top: 2%;

  .cardholder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .swiperholder{
    display:none;
  }
  .bg-style{
    position: absolute;
    height: 100%;
    width: 75%;
    left: 0px;
    z-index: 1;

  }
  
  .maintext {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
  }

  .inner {
    height: 100%;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  @media (max-width: 768px) {
    
    height:700px;
    padding-bottom: 50px;

    .cardholder {
    display: none;
  }
  .inner {
    margin-top: 10%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .swiperholder{
    display: grid;
    background-color: #f2f2f2;
    height: 100%;
    width: 100%;
  }

  .swiper {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;

}

.swiper-slide {
  background-color: #f2f2f2;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  }


;
`

export default HolidayPrompt;
